<template>
  <aside>
    <header>
      <h2>Shipping Addresses</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      <ul class="address-list">
        <li v-for="(address, index) in addresses" :class="[{'default': address.default}]" :key="`address_${index}`">
          <template v-if="('edit' in address)">
            <div class="creation">
              <input type="text" placeholder="First and last name..." class="form-control" v-model="address.name" />
              <input type="text" placeholder="Street address..." class="form-control" v-model="address.street1" />
              <input type="text" placeholder="Street address 2..." class="form-control" v-model="address.street2" />

              <div>
                <input type="text" placeholder="City" class="form-control" v-model="address.city" />

                <v-select class="state-selector" :options="stateOptions(address.country)" :placeholder="statePlaceholder(address.country)" v-if="statePlaceholder(address.country)" :clearable="false" v-model="address.state" :reduce="state => state.value" />
                <input type="text" v-model="address.state" placeholder="County/province" v-else class="form-control" />

                <input type="text" placeholder="Postal code..." class="form-control postal" v-model="address.postal" />
              </div>

              <div>
                <v-select :options="country_options" :clearable="false" class="country-selector" placeholder="Country..." v-model="address.country" @option:selected="$e => handleCountryChange($e, address)" :reduce="country => country.value" />
              </div>

            </div>

          </template>

          <template v-else>
            <address>
              <strong>{{address.name}}</strong>
              <span>{{address.street1}}</span>
              <span v-if="address.street2">{{address.street2}}</span>
              <span>{{address.city}}, {{address.state}} {{address.postal}}</span>
              <span>{{countryName(address.country)}}</span>
            </address>

            <div class="controls">
              <a href="#" class="btn btn-xs btn-outline-secondary" v-on:click.prevent="removeAddress(address)">Remove</a>
              <a href="#" class="btn btn-xs btn-outline-secondary" v-on:click.prevent="makeDefault(address)" v-if="!address.default">Make default</a>
              <a href="#" class="btn btn-xs btn-outline-success disabled" v-else><i class="fas fa-check"></i> Default</a>
            </div>
          </template>
        </li>
      </ul>
    </main>

    <footer>
      <div class="mr-auto">
        <button class="btn btn-light btn-sm" @click="close">Close</button>
      </div>
      <button class="btn btn-secondary btn-sm" v-if="!hasEdit" @click="addAddress">New address</button>
      <button class="btn btn-green btn-sm" v-if="hasEdit" @click="saveAddress">Save address</button>
    </footer>
  </aside>
</template>

<script>
export default {
  name: 'ShippingAddresses',
  props: ['data'],
  computed: {
    me() {
      return this.$store.state.user;
    },
    country_options() {
      return Object.keys(this.$geoData.all_countries).reduce( (res, item) => {
        res.push({
          label: this.$geoData.all_countries[item],
          value: item
        });
        return res;
      }, [] )
    },
    hasEdit() {
      return _.some( this.addresses, 'edit' );
    }
  },
  data() {
    return {
      addresses: []
    }
  },
  created() {
    this.addresses = [...this.me.addresses];
  },
  methods: {
    close() {
      this.$emit('done');
    },
    async saveAddress() {
      const address = this.addresses[this.addresses.length-1];
      const resp = await this.$api.User.ShippingAddresses.add(address);
      this.addresses = resp;

      this.$store.dispatch('updateUser', {addresses: resp});
    },
    async removeAddress(address) {
      const resp = await this.$api.User.ShippingAddresses.remove(address.id);
      this.addresses = resp;

      this.$store.dispatch('updateUser', {addresses: resp});
    },
    async makeDefault(address) {
      const resp = await this.$api.User.ShippingAddresses.make_default(address.id);
      this.addresses = resp;

      this.$store.dispatch('updateUser', {addresses: resp});
    },
    stateOptions(country) {
      if( country == 'US' ) {
        return Object.keys(this.$geoData.states).reduce( (res, item) => {
          res.push({
            label: this.$geoData.states[item],
            value: item
          });
          return res;
        }, [] )
      }
      if( country == 'CA' ) {
        return Object.keys(this.$geoData.ca_provinces).reduce( (res, item) => {
          res.push({
            label: this.$geoData.ca_provinces[item],
            value: item
          });
          return res;
        }, [] )
      }
    },
    countryName(country) {
      return this.$geoData.all_countries[country];
    },
    handleCountryChange(country, addr) {
      if( !this.statePlaceholder(country) ) addr.state = '';
      else addr.state = this.stateOptions(country)[0].value;
    },
    statePlaceholder(country) {
      switch(country) {
        case "US":
          return 'State...';
        case "CA":
          return 'Province...';
        case "UK":
          return null;
        case "IE":
          return null;
      }
    },
    addAddress() {
      this.addresses.push({
        edit: true,
        name: '',
        street1: '',
        street2: '',
        postal: '',
        city: '',
        state: this.stateOptions('US')[0].value,
        country: 'US'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.creation {
  flex: 1;
  > input {
    height: 32px;
    font-size: 14px;
    margin: 5px 0;
  }
  > div {
    display: flex;
    margin: 5px -5px;
    > input, > div {
      font-size: 14px;
      height: 32px;
      margin: 0 5px;
    }
  }
  >small {
    display: block;
    font-size: 12px;
    color: rgb(89,89,89);
    margin: 10px 0 0;
  }
  .state-selector {
    width: 150px;
    flex: 0 0 150px;
  }
  .country-selector {
    width: 100%;
    flex: 1;
  }

  .postal {
    width: 120px;
  }

  ::v-deep .v-select {
    

    .vs__dropdown-menu {
      width: auto;
      border-radius: 0 4px 4px 4px;
      border-top: 1px solid #E3E0DE;
    }
  }
}

.address-list {
  padding: 3px 0 0;
  margin: 0 0;
  display: block;

  > li {
    display: flex;
    position: relative;
    padding: 15px 15px;
    align-items: center;
    background: #fff;
    box-shadow: rgba(0,0,0,0.05) 0 3px 6px, rgba($light, 0.5) 0 0 0 1px;
    border-radius: 10px;

    +li {
      margin: 15px 0 0;
    }

    > address {
      flex: 0 0 0;
      display: flex;
      flex-direction: column;
      white-space: nowrap;
      margin: 0;

      > span, > strong {
        display: block;
      }
    }

    > .controls {
      margin: 0 0 0 auto;
      padding: 0 0 0 35px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      >.btn {
        +.btn {
          margin: 5px 0 0;
        }
      }
    }

    &.default {
      box-shadow: $green 0 0 0 1px, rgba($green, 0.3) 0 0 0 3px;
    }
  }
}
</style>